
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';

import { strings } from '@/lib/stringConst';
import UserEntityModule from '@/store/settings/users/entity';
import { getChiefs, getSecurityGroupsListAll } from '@/api/users';
import { getAllPartnersList } from '@/api/partners';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { UserTextFields } from '@/interfaces/models/user.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  emits: ['returnBefore'],
  components: {
    GuiFormCheckbox,
    AppCol,
    AppRow,
    AppFieldset,
    FormInput,
    FormTextarea,
    FormSelect,
    FormButton,
    FormTimePickerRange,
  },
})
export default class UsersEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  lastName = this.model.lastName ?? '';
  firstName = this.model.firstName ?? '';
  login = this.model.login ?? '';
  comment = this.model.comment ?? '';
  password = '';
  isActive = this.model.isActive ?? false;
  chiefState = this.model.chiefState ?? false;

  isInvalidPassword = true;
  needSendPassword = false;

  chief = this.model.chief ?? { id: null, value: '' };
  chiefItems: { id: string; value: string }[] = [];
  securityGroup = this.model.securityGroup ?? { id: null, value: '' };
  securityGroupItems: { id: string; value: string }[] = [];

  partner = this.model.partner ?? { id: null, value: '' };
  partnerItems: { id: string; value: string }[] = [];

  rules: Record<string, (value: string) => true | string> = {
    firstLastName: (value: string) => {
      if (!value.length) return true;

      return /^[A-Za-zА-Яа-яЁёЪъ\- ]+$/.test(value) || 'Введены некорректные символы';
    },
    passwordMinChars: (value: string) => {
      if (this.typeAction === 'edit' && !value.length) return true;

      return value.length >= 8 || 'Не менее 8 символов';
    },
    password: (value: string) => {
      const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{0,}$/g;

      if (this.typeAction === 'edit' && !value.length) return true;

      return pattern.test(value) || 'Заглавные, строчные латинские буквы и цифры';
    },
    login: (value: string) => {
      const pattern = /\s/g;

      return !pattern.test(value) || 'Пробелы не допустимы в Логине';
    },
  };

  get text() {
    return strings;
  }

  get model() {
    return UserEntityModule.model;
  }

  get requestData(): UserTextFields {
    const data: UserTextFields = {
      firstName: this.firstName,
      lastName: this.lastName,
      login: this.login,
      comment: this.comment,
      chief: this.chief.id ? this.chief.id.toString() : 'null',
      chiefState: this.chiefState ? '1' : '0',
      isActive: this.isActive ? '1' : '0',
      workTimeFrom: this.workTime.range[0] + ':00',
      workTimeTo: this.workTime.range[1] + ':00',
      securityGroup: this.securityGroup.id as string,
      partner: this.partner.id as string,
    };

    if (this.password.length) {
      data.password = this.password;
      data.needSendPassword = this.needSendPassword ? '1' : '0';
    }

    return data;
  }

  returnBefore() {
    this.$emit('returnBefore');
  }

  handlePasswordError({ result: isError }: { result: boolean }): void {
    this.isInvalidPassword = isError;
  }

  async setChiefsItems() {
    try {
      const users = await getChiefs();
      this.chiefItems = users.map((chief: { id: string; fullName: string }) => {
        return {
          id: chief.id,
          value: chief.fullName,
        };
      });
    } catch (error) {
      this.chiefItems = [];
    }
  }

  async setSecurityGroupItems(): Promise<void> {
    const securityGroups = await getSecurityGroupsListAll();
    this.securityGroupItems = securityGroups.map((group: { id: string; name: string }) => {
      return {
        id: group.id,
        value: group.name,
      };
    });
  }

  async setPartnerItems(): Promise<void> {
    const partnerList: PartnerSelectListItem[] = await getAllPartnersList();

    this.partnerItems = partnerList.map((partner) => ({
      id: partner.uuid,
      value: partner.legalName,
      name: partner.legalName,
    }));
  }

  get workTime() {
    const from = `${this.model.workTimeFrom.hour}:${this.model.workTimeFrom.minute}`;
    const to = `${this.model.workTimeTo.hour}:${this.model.workTimeTo.minute}`;
    const value = `${from}-${to}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  selectWorkTime(value: {
    to: { hour: string; minute: string; value: string };
    from: { hour: string; minute: string; value: string };
  }) {
    this.model.workTimeFrom = {
      hour: value.from.hour,
      minute: value.from.minute,
    };
    this.model.workTimeTo = {
      hour: value.to.hour,
      minute: value.to.minute,
    };
  }

  async add() {
    try {
      if (await UserEntityModule.addNew(this.requestData)) {
        this.returnBefore();
      }
    } catch (e) {
      console.debug('error');
    }
  }

  async edit() {
    try {
      if (
        await UserEntityModule.saveUpdate({
          id: this.$route.params.id as string,
          data: this.requestData,
        })
      ) {
        this.returnBefore();
      }
    } catch (e) {
      console.debug('error');
    }
  }

  async created() {
    if (this.typeAction === 'add') {
      this.isActive = true;
    }

    await this.setChiefsItems();
    await this.setSecurityGroupItems();
    await this.setPartnerItems();
  }
}
